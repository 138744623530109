
/* These are needed to keep the aspect ratio of the images */
.item-card {
  overflow: hidden;
}
.item-card .s-image {
  margin: 0 auto;
}

amplify-s3-image {
	/* --width: 100%; */
	--height: 125px;
}