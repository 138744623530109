.column.img-container .member-img {
  /* border-radius: 50%; */
  object-fit: cover !important;

  height: 151px !important;
}

.member-back {
  text-align: center;
  background-color: #fff;
  opacity: 1;
  height: 25%;
  position: absolute;
  bottom: 0 !important;
  padding: 0.4em 0;
  width: 100%;
}
.member-name {
  position: absolute;
  bottom: 9% !important;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8em;
  color: #303141;
}

.img-container:hover,
.img-container:focus {
  transform: translateY(-3px);
}

.test-container:hover,
.test-container:focus {
  box-shadow: rgba(16, 14, 23, 0.1) 5px 8px 10px 1px !important;
}

/* #round-box:hover,
#round-box:focus #overlay {
  background-color: red !important;
} */

#hidden-text {
  display: none;
  color: #fff;
  position: absolute;
  bottom: 10% !important;
  left: 50%;
  transform: translateX(-50%);
  /* font-size: 0.9em; */
  /* font-weight: 600 !important; */
}
#overlay {
  transition: all ease-in-out 0.3s;
}

#round-box:hover #overlay {
  background-color: #355069 !important;
  height: 35%;
}

#round-box:hover ~ #member-name {
  color: #fff;
}

#round-box:hover + #hidden-text {
  display: unset;
}
.ui.card.member-card-container {
  margin-right: 1em;
  width: 195px !important;

  /* border-radius: 50%; */
  background-color: rgba(226, 226, 226);
  border: transparent !important;
  box-shadow: rgba(16, 14, 23, 0.1) 5px 5px 10px 1px;
}
a.ui.card.member-card-container:hover,
a.ui.card.member-card-container:focus {
  border: none !important;
  border: transparent !important;

  box-shadow: rgba(16, 14, 23, 0.1) 5px 5px 10px 1px;
}

.member-card-container .add-studio-member {
  color: rgba(74, 78, 82, 0.3);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
}

.el-dialog__wrapper
  .el-dialog.el-dialog--large.dialog.create-studio.add-member-form {
  top: 30% !important;
}

.members-tag {
  margin-top: 1em !important;
  margin-right: 0.5em !important;
}

.ui.card .extra.content.extra-remove {
  padding: 0 !important;
  margin-bottom: -3px !important;
  border-radius: 0 0 6px 6px !important;
}

.extra-remove .ui.fluid.button {
  border-radius: 0 !important;
  background-color: #6f8497;
  color: #fff;
  font-weight: 300;
}

.extra-remove .ui.fluid.button:hover,
.extra-remove .ui.fluid.button:focus {
  background-color: #355069;
  font-weight: 500;
}
