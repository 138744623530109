
button.dialog-close {
	position: absolute;
	top: 0;
	right: 0;
}

.image-picker2 .image-picker-container {
	position: relative;
	border: 1px solid rgba(74, 78, 82, 0.3);
	border-radius: 50%;
	width: 100px;
	height: 100px;
	transition: border .2s ease-in-out;
	margin: 5px 0;
}

.image-picker2 .image-picker-container .icon.upload {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2rem;
	color: rgba(74, 78, 82, 0.6);
}

.image-picker-container:hover {
	border-color: rgba(0, 0, 0, 0.55);
	cursor: pointer;
}

.image-picker2 .uploaded {
	color: #13ce66;
}