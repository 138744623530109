.text-style {
  color: #d4a74c !important;
  font-size: 1.2em;
  font-weight: 300;
}

.ui.card > .image,
.ui.cards > .card > .image {
  /* width: 210px;
  min-height: 153px;
  max-height: auto; */
  height: 9em;
}
.ui.card > .image > img,
.ui.cards > .card > .image > img {
  height: 9em;
  object-fit: cover !important;
}

.title-text {
  font-size: 1.3em !important;
  margin-bottom: 0.8em !important;
}

.content > .header > .card-header-text {
  font-size: 0.8em !important;
  text-transform: initial;
  color: #101224 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display: block;
  max-width: 7.5em !important;

  /* display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}

.ui.card .content {
  height: 4.6em;
  width: 100%;
  text-align: left;
  padding: 0.5em;
}
.ui.card .extra.content {
  height: 2.8em;
  padding-top: 0;
  padding-left: 0.8em !important;
  border-top: none !important;
  overflow: hidden !important;
}

.content > .header {
  padding-left: 0px;
  padding: 0;
  margin-bottom: -0.1em !important;
}

.item-card.ui.link {
  text-decoration: none !important;
  border: none !important;
  box-shadow: rgba(16, 14, 23, 0.1) 5px 5px 10px -2px;
}

.item-card.ui.link:hover,
.item-card.ui.link:focus {
  box-shadow: rgba(16, 14, 23, 0.2) 8px 8px 15px -2px;
}

.item-card .btn-feature {
  position: absolute;
  right: 0;
  top: 0;
  width: auto !important;
  padding: .75em;
}
i.star.outline {
  color: black;
}
i.star {
  color: #d4a74c;
  margin: 0 !important;
}

.content .meta .card-owner {
  font-weight: 400 !important;
  font-size: 0.8em !important;
  text-decoration: none !important;
  /* color: #101224 !important; */
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display: block;
  max-width: 8em !important;
  transition: all linear 0.2s;
}

.content .meta .card-owner:hover,
.content .meta .card-owner:focus {
  color: #d4a74c;
}

.item-tag {
  background-color: #424f65 !important;
  background-color: #fff !important;

  color: #fff !important;
  color: #424f65 !important;
  border: none !important;
  border: solid 0.1em rgba(66, 79, 101, 0.4) !important;
  border-radius: 500px !important;
  padding: 0em 0.8em 0em 0.8em !important;
  font-size: 0.7em !important;
  font-weight: 300 !important;
  margin: 0 !important;
  margin-right: 0.4em !important;
}

.el-tag {
  height: 2.2em !important;
}



.recomm-background {
  background-color: #cdebe9;
  margin-left: -5em !important;
  margin-right: -5em !important;
  padding: 5em !important;
}

.ui.card.subcard-img > .image,
.ui.cards.subcard-img > .card > .image {
  /* width: 210px;
  min-height: 153px;
  max-height: auto; */
  height: 5em !important;
}
.ui.card.subcard-img > .image > img,
.ui.cards.subcard-img > .card > .image > img {
  height: 5em;
  object-fit: cover !important;
}

.recomm-background .content > .header > .card-header-text {
  font-size: 0.8em !important;
}
.recomm-background .content .meta .card-owner {
  font-size: 0.7em !important;
}

.title-text.recomm-text {
  margin-top: -1em !important;
  /* margin-bottom: 0.4em !important; */
}

.item-card.ui.link {
  cursor: auto !important;
}

.ui.card.item-card.ui.link .s-image > div > div > img {
  height: 9em !important;
  object-fit: cover !important;
  display: block !important;
  width: 100% !important;
}

.home-project-list {
  margin-top: 2em;
  margin-bottom: 8em;
}
