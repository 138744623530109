.profile-page {
  margin-left: -5em !important;
  margin-right: -5em !important;
  font-family: "Lato", sans-serif !important;
}

.profile-background {
  padding-top: 1.5em !important;
  background-color: #252d3a !important;
}

.profile-container {
  padding: 2em 8em 4em 8em !important;
}

.profile-text {
  color: #fff;
  letter-spacing: 0.05em;
}

.profile-user {
  font-size: 2.1em;
}

.profile-intro {
  font-weight: 200;
  font-style: italic;
  font-size: 1.1em;
  margin-top: -0.5em;
}

.tag-dot {
  width: 5px !important;
  height: 5px !important;
  background-color: #d4a74c !important;
  border-radius: 50% !important;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block !important;
  margin-right: 0.2em;
  margin-bottom: 0.1em;
  /* padding: 0px 7px !important; */
}

.ui.label.profile-tag {
  background-color: transparent !important;
  border: solid 0.1em rgba(212, 167, 76, 0.7) !important;
  border-radius: 500px !important;
  color: #d4a74c !important;
  font-weight: 300 !important;
  height: 2.1em;
  padding: 0.4em 1em;
  margin-right: 0.4em;
  margin-top: 1em;
}

.data-name {
  text-transform: initial !important;
  color: #fff !important;
}

.data-sta {
  color: #fff !important;
}

.ui.statistic > .label,
.ui.statistics .statistic > .label {
  font-size: 0.8em !important;
  font-weight: 200 !important;
  margin-bottom: 1em !important;
}

.ui.statistic > .value,
.ui.statistics .statistic > .value {
  font-size: 1.2em !important;
}

.data-group {
  display: inline-block !important;
  position: absolute;
  right: 12em !important;
  top: 6em !important;
}

.first-data {
  margin: 0 0 0 1.5em !important;
}

.profile-content {
  padding: 2.5em 5em;
}

.profile-search {
  text-align: center !important;
  margin-top: -4em !important;
}

.profile-search .search-style .el-input__inner {
  background-color: #fff;
  box-shadow: rgba(16, 14, 23, 0.3) 0px 0px 30px 0px;
}

.profile-search .el-button {
  box-shadow: rgba(16, 14, 23, 0.3) 10px 0px 30px 0px;
}

.ui.secondary.pointing.menu .active.item {
  color: #252d3a;
  border-color: #5fbdb9;
  font-size: 0.9em;
}

.ui.secondary.pointing.menu .active.item:hover,
.ui.secondary.pointing.menu .active.item:focus {
  border-color: #5fbdb9;
}

.ui.secondary.pointing.menu .item {
  font-weight: 400;
  font-size: 0.9em;
  color: #9b9b9b;
}

.ui.secondary.pointing.menu {
  border-bottom: none;
}

.profile-items .ui.segment {
  box-shadow: none;
  border: none;
  border-radius: none;
}

.load-button {
  text-align: center;
  margin-top: 3em;
}

.ui.button.profile-button {
  background-color: #5fbdb9;
  border-radius: 2px;
  color: #fff;
  padding: 1.1em 4.3em;
  box-shadow: rgba(16, 14, 23, 0.1) 5px 0px 10px 0px;
  font-weight: 400;
  transition: all linear 0.2s;
  letter-spacing: 0.1em;
}

.ui.button.profile-button:hover,
.ui.button.profile-button:focus {
  background-color: #308984 !important;
}

.profile-recom {
  margin-top: 5em !important;
  margin-left: -6em !important;
  margin-right: -6em !important;
  margin-bottom: -3em !important;
  padding: 5em;
  background-color: #cdebe9;
}

.items-empty {
  margin-bottom: 3em !important;
  font-size: 1.3em;
  color: rgba(0, 0, 0, 0.3);
}
