
.dx-ui-loading {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	position: absolute;
	top: 0; bottom: 0;
	left: 0; right: 0;

	background: rgba(255, 255, 255, 0.75);
	z-index: 100000000;
}
