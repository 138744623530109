.studio-page .profile-background {
  background-color: #cdebe9 !important;
  /* background-color: #3caba5 !important;
  background-color: #35bdb2 !important; */
}

.studio-page .profile-text.profile-user {
  color: #101224 !important;
}

.studio-page .profile-text.profile-intro {
  color: #525361 !important;
  font-weight: 300 !important;
}

.studio-page .studio-data-group .ui.statistic .label.data-name {
  color: #303141 !important;
  /* color: #101224 !important; */
  font-weight: 300 !important;
}

.studio-page .studio-data-group .ui.statistic .value.data-sta {
  color: #303141 !important;
  /* color: #101224 !important; */
  font-weight: 600 !important;
}

.studio-page .ui.label.profile-tag {
  background-color: #c6c7cb !important;
  background-color: rgba(37, 45, 58, 0.7) !important;
  color: #fff !important;
  border-color: transparent !important;
}

.studio-page .tag-dot {
  background-color: #fff !important;
}

.profile-page.studio-page .profile-search .search-style .el-input__inner {
  box-shadow: rgba(16, 14, 23, 0.1) 0px 0px 30px 0px;
}

.profile-page.studio-page .profile-search .el-button {
  box-shadow: rgba(16, 14, 23, 0.2) 10px 0px 30px 0px;
}

.studio-btn {
  background: transparent !important;
  border: 1px solid #ddac4f !important;
  border-radius: 500px !important;
  padding: 0.5em 1em !important;
  font-size: 0.8em !important;
  font-weight: 400 !important;
  color: #ddac4f !important;
  margin-right: 0.7em !important;
  transition: all linear 0.2s !important;
  /* background-color: #ddac4f !important;
  color: #fff !important; */
}

.studio-btn:hover,
.studio-btn:focus {
  background-color: #ddac4f !important;
  color: #fff !important;
}

.ui.divider.update-divider {
  margin-bottom: 0.2em !important;
}

.update-studioImg {
  color: rgba(0, 0, 0, 0.9) !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  .profile-page.studio-page .profile-search .el-button {
    position: absolute;
    top: -4.6rem;
    right: -22.5rem;
  }
  .studio-page h1.profile-text.profile-user {
    font-size: 1.5rem !important;
  }
  .data-group {
    right: 9em !important;
    top: 7em !important;
  }
  .studio-page .studio-data-group .ui.statistic .label.data-name {
    font-size: 0.6rem !important;
  }
  .studio-page .studio-data-group .ui.statistic .value.data-sta {
    font-size: 1.1rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .profile-page.studio-page .profile-search .el-button {
    position: absolute;
    top: -4.6rem;
    right: -22.5rem;
  }
  .studio-page h1.profile-text.profile-user {
    font-size: 1.5rem !important;
  }
  .data-group {
    right: 8em !important;
    top: 7em !important;
  }
  .studio-page .studio-data-group .ui.statistic .label.data-name {
    font-size: 0.5rem !important;
  }
  .studio-page .studio-data-group .ui.statistic .value.data-sta {
    font-size: 0.9rem !important;
  }
}
