footer {
  background-color: #252d3a;
  padding: 1.5em 5em !important;
}

.background .data-logo {
  width: 1.8em !important;
}

.background .logo-text {
  font-size: 1.2em !important;
  font-weight: 400 !important;
}
.nav-text.footer-item {
  font-size: 0.9em !important;
}

.ui.secondary.menu .item.footer-icon {
  padding: 0 !important;
  color: #fff !important;
  font-size: 1.1em !important;
  transition: all linear 0.2s;
}

.ui.secondary.menu .item.footer-icon:hover,
.ui.secondary.menu .item.footer-icon:focus {
  color: #3caba5 !important;
}

.copyright-text {
  font-size: 0.7em;
  letter-spacing: 0.1em;
  color: rgba(225, 225, 225, 0.8) !important;
  text-align: center;
  margin-top: -1em !important;
}

.copyright-text:hover,
.copyright-text:focus {
  color: rgba(225, 225, 225, 0.8) !important;
}
