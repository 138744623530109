.prof-project-list .ui.card,
.ui.cards > .card {
  width: 195px;
  margin: 0 1em 1em 0;
}

.ui.cards > .card.create-project {
  margin: 0 1em 1em 0;
  background-color: #e2e2e2;
  border: none;
  box-shadow: rgba(16, 14, 23, 0.1) 5px 5px 10px -2px;
}

.prof-project-list .create-project.ui.link p.add-project {
  font-size: 3.3em !important;
  font-weight: 600 !important;
  border: solid rgba(74, 78, 82, 0.3) 0.09em !important;
  border-radius: 50% !important;
  padding: 0px !important;
  width: 1.5em !important;
  height: 1.5em !important;
  line-height: 1.2em !important;
  text-decoration: none !important;
  text-align: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: rgba(74, 78, 82, 0.3);
}

.ui.cards > .card.create-project:hover,
.ui.cards > .card.create-project:focus {
  /* box-shadow: rgba(16, 14, 23, 0.3) 10px 0px 30px 0px; */
  box-shadow: rgba(16, 14, 23, 0.2) 8px 8px 15px -2px;
}

.prof-project-list .date.link.card-owner:hover,
.prof-project-list .date.link.card-owner:focus {
  color: rgb(49, 70, 95);
  cursor: unset;
}
