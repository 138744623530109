

.text-loop-wrapper {
	display: inline-block;
	position: relative;
	text-align: left;
	transition: all .3s ease-in-out;
}
.text-loop-wrapper > span {
	display: inline-block;
	white-space: nowrap;
	top: 0;
	left: 0;
}
.text-loop .is-visible {
	position: relative;
	opacity: 1;
	animation: push-in 0.6s;
}
.text-loop .is-hidden {
	position: absolute;
	opacity: 0;
	animation: push-out 0.6s;
}
@keyframes push-in {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}
	60% {
		opacity: 1;
		transform: translateY(-10%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes push-out {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	60% {
		opacity: 0;
		transform: translateY(-110%);
	}
	100% {
		opacity: 0;
		transform: translateY(-100%);
	}
}
