.project-page-container {
  padding: 2em 0;
}

.dx-component {
  position: relative;
  margin: 2em .75em;
}
@media (min-width: 1200px)  {
  .dx-component {
    margin: 2em 3em;
  }
}
.dx-text {
  border: 1px solid transparent;
  padding: .5em;
}
.dx-text:hover {
  border: 1px dotted gray;
  cursor: pointer;
}
.dx-component:first-of-type {
  margin-top: 0em;
}
.dx-component:last-of-type {
  margin-bottom: 0em;
}
.dx-component iframe {
  width: 100%;
  border-radius: 5px;
  border: none;
  box-shadow: rgba(16, 14, 23, 0.2) 0px 0px 30px 0px;
}
.dx-component .overlay-helper {
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
}
.dx-component.active .overlay-helper {
  display: none;
}
body {
  height: auto !important;
}
.comments-box {
  display: block !important;
}

.comment-container {
  display: block !important;
  padding: 5em 5em !important;
  background-color: #fff;
}

#project-name {
  font-size: 1.5em;
  color: #101224;
}

.project-sta {
  font-size: 1.5em;
  font-weight: 600;
  color: #101224;
}

.sta-item {
  font-size: 0.9em;
  color: rgba(16, 18, 36, 0.6);
  margin-left: 0.2em;
  margin-right: 1em;
}

.project-buttons {
  text-align: right;
}

.project-container #project-img {
  overflow: hidden !important;
  height: 135px !important;
  width: 135px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

#project-img > img {
  object-fit: cover !important;
  height: 135px !important;
  width: 135px !important;
  background-color: #fff;
  border-radius: 50%;
  border: 2.5px solid #e6ebf1;

  /* box-shadow: rgba(16, 14, 23, 0.2) 8px 0px 20px 0px !important; */
}

#project-img.tiny, #project-img.tiny img {
  height: 75px !important;
  width: 75px !important;
}

.project-btn {
  font-size: 0.7em !important;
  padding: 5px !important;
  width: 4.4em !important;
  height: 4.4em !important;
  margin-right: 0.6em !important;
  transition: all linear 0.2s !important;
}

.project-btn:hover,
.project-btn:focus {
  background: rgba(60, 171, 165, 0.2) !important;
  color: rgba(60, 171, 165, 1) !important;
}

.mx-1.item-tag.project-detail-tag {
  margin-top: 1.3em !important;
  line-height: 2.2em !important;
  padding: 0 1em 0 0.9em !important;
}

.tag-dot.detail-dot {
  background-color: #8a9298 !important;
  margin-right: 0.5em !important;
}

.codap-active main {
  background: rgba(0,0,0,0.3);
}
.codap-active {
  overflow: hidden;
  background: rgba(0,0,0,0.3);
}
.codap-active .dx-component:not(.active) {
  opacity: .5;
}

/* desktop */
/* @media (hover: hover) { */
  .dx-toolbar {
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    transition: all .3s ease-in-out;
    padding: .5em 0;
  }
  .dx-component:hover .dx-toolbar {
    opacity: 1;
    right: -43px;
  }
  .dx-toolbar .buttons {
    flex-direction: column;
  }
/* } */

/* touchscreen */
/* @media (hover: none) {
  .dx-toolbar {
    text-align: right;
    padding: .5em 0;
  }
} */
