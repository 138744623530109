.studio-header.header-style.intro-header {
  padding-bottom: 4.1em !important;
}

.studio-header.header-style.intro-header .background-img {
  width: 75%;
}

.intro-title {
  text-align: center;
  margin-top: 5em;
}

.intro-text {
  font-family: "Lato", sans-serif !important;
  font-size: 2em;
  font-weight: 400 !important;
  color: #101224 !important;
  letter-spacing: 0.03em;
}

.intro-container {
  margin-top: 4em;
  padding-bottom: 15em;
  padding-left: 6em !important;
  padding-right: 6em !important;
  font-family: "Lato", sans-serif !important;
  color: #101224 !important;
  position: relative !important;
}

.intro-column {
  text-align: center !important;
}

.intro-container .ui.card > .image,
.ui.cards > .card > .image {
  height: 50% !important;
  margin-top: -0.5em !important;
  background-color: #fff;
}

.intro-container .ui.card > .image > img,
.ui.cards > .card > .image > img {
  height: 50% !important;
}

.intro-container .ui.card > .image > img,
.ui.cards > .card > .image > img {
  display: unset !important;
  width: 50% !important;
}

.intro-container .ui.fluid.card.feature-card {
  box-shadow: none !important;
}

.intro-container .ui.card > .content,
.ui.cards > .card > .content {
  border-top: none !important;
}

.feature-title {
  text-transform: unset !important;
  font-size: 1.5em !important;
  font-weight: 400 !important;
}

.content.feature-desc {
  padding-left: 3em !important;
  padding-right: 3em !important;
}

.feature-info {
  font-size: 1.1em !important;
  line-height: 1.4em !important;
}

.feature-btn-container {
  margin-top: 5em;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  -ms-transform: translate(-50%);
}

.ui.button.feature-btn {
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 1.1em !important;
  padding: 1.1em 2.1em !important;
  background-color: #d4a74c !important;
  border-radius: 1px !important;
  transition: all linear 0.2s !important;
  box-shadow: rgba(16, 14, 23, 0.1) 2px 2px 10px 0px;
}

.ui.button.feature-btn:hover,
.ui.button.feature-btn:focus {
  background-color: #c29f58 !important;
}
.intro-bkg .intro-footer {
  padding-left: 2em !important;
  padding-right: 2em !important;
}
.intro-bkg .intro-footer > .data-logo {
  height: 1.8em !important;
}

.background.intro-bkg .nav-text.copyright-text {
  text-align: center !important;
  float: unset !important;
}
