.studio-header {
  background-color: #252d3a !important;
  font-family: "Lato", sans-serif !important;
  color: #101224 !important;
}

.studio-header.header-style {
  margin-left: -5em !important;
  margin-right: -5em !important;
  padding-top: 5em !important;
  padding-bottom: 2.12em !important;
}

.background-img {
  width: 75%;
  box-shadow: rgba(16, 14, 23, 0.2) 10px 0px 40px 0px;
}

.header-title {
  margin-top: -5.1em;
  font-size: 3.8em;
  font-weight: 600 !important;
  letter-spacing: 0.05em;
}

.header-title-sub {
  font-weight: 300 !important;
  margin-left: 0.2em;
}
.header-subtitle {
  font-size: 1.4em;
  font-weight: 400;
  margin-top: 0.1em;
  letter-spacing: 0.04em;
}
.header-align-button {
  padding-left: 0 !important;
}
.header-button {
  display: inline-block !important;
}

.header-button .button {
  background-color: #424f65 !important;
  border-radius: 1px !important;
  padding: 1em 1.5em !important;
  margin: 1.2em 0.6em !important;
  color: white !important;
  font-weight: 300 !important;
  font-size: 1.2em !important;
  letter-spacing: 0.04em;
  box-shadow: rgba(16, 14, 23, 0.2) 8px 0px 20px 0px;
}

.header-icon {
  margin-right: 0.8em !important;
  margin-left: -0.3em !important;
  color: #ddac4f;
}

.header-button .hidden.content {
  color: #eac71f;
  /* color: #d4a74c; */
  font-weight: 500;
  /* box-shadow: rgba(16, 14, 23, 0.3) 10px 0px 20px 0px !important; */
}

.header-button .button:hover,
.header-button .button:focus + .header-button .hidden.content {
  box-shadow: rgba(16, 14, 23, 0.2) 8px 0px 20px 0px;
}

.search-item {
  text-align: center;
  margin-top: 5em;
}

.search-item > h4 {
  font-size: 1.8em;
  font-weight: 400;
  color: #101224 !important;
}
.search-form {
  margin-top: 2.5em !important;
}
.search-style .el-input__inner {
  border-radius: 50px !important;
  background: rgba(197, 197, 197, 0.3);
  border: none !important;
  padding: 1.5em 28em 1.5em 8em !important;
  font-family: "Lato", sans-serif !important;
  color: #4a4a4a !important;
  font-weight: 300 !important;
}

.el-input {
  margin-left: 1em !important;
}

.el-button {
  margin-left: -3em !important;
  transition: all linear 0.3s !important;
}

.el-button--info {
  background-color: #ddac4f !important;
  padding: 1em 1.8em !important;
  border-radius: 50px !important;
  border: none !important;
  color: #101224 !important;
  font-family: "Lato", sans-serif !important;
}
.el-button:hover,
.el-button:focus {
  background: #d4a74c !important;
}

.ui.dropdown {
  margin-left: 2.4em;
  margin-top: 0.7em;
  position: absolute;
  z-index: 3 !important;
}

.el-dialog__wrapper .el-dialog.el-dialog--large.dialog.create-studio {
  top: 9% !important;
  padding: 0.2em 1em 1em 1em !important;
  max-width: 700px !important;
  border-radius: 0.3rem !important;
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.el-dialog__wrapper .el-dialog__headerbtn {
  display: none !important;
}

.el-dialog.el-dialog--large.dialog.create-studio .ui.divider {
  margin-left: -1em !important;
  margin-right: -1em !important;
  margin-bottom: 0;
  margin-top: 1.2em;
}

.el-dialog.el-dialog--large.dialog.create-studio
  .el-dialog__footer
  .ui.divider {
  margin-left: -2.3em !important;
  margin-right: -2.3em !important;
}

.el-button.el-button--default.cancel-button {
  margin-right: 4em !important;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  color: #fff;
  background-color: #1b1c1d;
  border: none !important;
  padding: 0.8em 1.5em;
}

.create-studio .Form__formSection___1PPvW {
  background: none;
  box-shadow: none;
  padding: 0em 1em 2.4em 1em;
  margin-bottom: 0;
}

.create-studio .Form__formSection___1PPvW input {
  height: 250% !important;
  width: 20em !important;
  height: 10em !important;
  left: -10em !important;
  top: -12em !important;
}

.create-studio .Section__sectionHeader___2djyg {
  display: none;
}

/* .Section__sectionBody___3DCrX {
  width: 108px !important;
} */

.create-studio .Section__sectionBody___ihqqd {
  display: none !important;
}

.create-studio .Form__formContainer___1GA3x {
  padding: 0 !important;
}

.create-studio .Form__formSection___1PPvW {
  padding: 0 !important;
}

.create-studio .PhotoPicker__photoPlaceholder___2JXO4 {
  padding: 2.6em 0 2.2em !important;
  border-radius: 5px !important;
  border: 1px dashed #c0ccda !important;
  display: none !important;
}

/* .PhotoPicker__photoPlaceholderIcon___1eBTV svg {
  width: 2.2em !important;
  height: 2.2em !important;
  opacity: 0.5 !important;
} */

/* .Section__sectionHeader___13iO4 .Section__sectionBody___3DCrX:hover,
.Section__sectionHeader___13iO4
  .Section__sectionBody___3DCrX:focus
  > .PhotoPicker__photoPlaceholder___1YUHz {
  border: 1px dashed #3caba5 !important;
  background: red !important;
} */

.require-upload-img {
  text-align: center;
  margin-top: -3em !important;
  margin-bottom: 2em;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-size: 13.3px !important;
}

.require-upload-img .required-star {
  color: red;
}

.add-cover {
  text-align: center;
  margin-bottom: 2em;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.3) !important;
  font-size: 0.9em !important;
  font-family: "Lato", sans-serif !important;
}

.add-cover > span {
  color: red;
}

.el-form-item__label {
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 0.95em !important;
  font-family: "Lato", sans-serif !important;
}

.el-input {
  margin-left: 2px !important;
}

.el-input__inner {
  border-radius: 0.2rem !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.create-studio ::placeholder {
  color: rgba(224, 225, 226, 1) !important;
  font-family: "Lato", sans-serif !important;
  font-size: 0.9em !important;
}

.el-dialog__header {
  padding-top: 1.2em !important;
}

/* .inline-form {
  justify-content: space-between !important;
  display: flex;
} */

.studio-name .el-input,
.el-input__inner {
  width: 100% !important;
  /* width: 22em; */
}

.studio-tags .el-input,
.el-input__inner {
  width: 150%;
  width: 22em;
}

.el-form--inline .el-form-item.studio-tags {
  margin-right: 0px;
}

.add-member .el-input,
.el-input__inner {
  width: 45em;
}

.add-member .el-button.el-button--primary {
  width: 10em !important;
  background: #eff3f6;
  border-color: rgba(146, 146, 146, 0.5);
  /* color: rgba(36, 41, 46, 0.4); */
  border-radius: 2px !important;
  /* background: #e0e1e2 !important; */
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9em !important;
  font-family: "Lato", sans-serif !important;
}

.add-member .el-button {
  margin-left: 0 !important;
  border: none;
  font-size: 0.9em !important;
  line-height: 1.3;
  margin-right: -11px !important;
}

.add-member .el-input-group__append,
.el-input-group__prepend {
  padding-left: 0;
  border-color: rgba(146, 146, 146, 0.5);
  border-radius: 2px !important;
  font-size: 0.9em !important;
  margin-right: -11px !important;
}

.add-member .el-button.el-button--primary:hover,
.add-member .el-button.el-button--primary:focus {
  background: #e0e1e2 !important;
  color: rgba(0, 0, 0, 0.87);
}

.el-dialog__body {
  padding: 20px 20px 0px 20px !important;
}

.el-input__inner:active,
.el-input__inner:focus {
  border-color: rgba(0, 0, 0, 0.5) !important;
}

.el-form-item.is-required.member-container {
  margin-bottom: 1em;
}

.form-footer {
  margin-top: 2em;
}

.create-studio .el-dialog__body .image-preview {
  border-radius: 5px !important;
  border: 1px dashed #c0ccda !important;
}

.image-container {
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px !important;
  left: 50%;
  transform: translateX(-50%);
  /* margin-bottom: -3.6em; */
  margin-bottom: 3em;
  margin-top: 0.6em;
}

.create-studio .check-img {
  background: #13ce66;
  width: 40px;
  height: 24px;
  text-align: center;
  transform: rotate(45deg);
  box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  display: block;
  position: absolute;
  right: -15px;
  top: -6px;
  width: 37px;
  height: 22px;
}

.check-img .check.icon {
  color: #fff;
  font-size: 0.7em;
  margin-top: 8px;
  margin-left: -2px;
  transform: rotate(-45deg) scale(0.8);
  display: inline-block;
}

.Section__sectionBody___3DCrX {
  color: transparent;
  margin-top: 3em !important;
  display: none !important;
}

.Form__formContainer___cu04J {
  margin-top: -6em !important;
}

.form-footer .el-button.el-button--primary.is-disabled {
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  color: rgba(0, 0, 0, 0.2);
  background-color: #e0e1e2 !important;
  border: none !important;
  padding: 0.8em 2.8em;
}

.form-footer .el-button.el-button--primary.is-disabled:hover,
.form-footer .el-button.el-button--primary.is-disabled:focus {
  background-color: #e0e1e2 !important;
}

/* .el-button.el-button--primary.is-disabled .checkmark.icon {
  width: 36px;
  background: rgba(0, 0, 0, 0.1);
  height: 36px;
} */

.form-footer .el-button.el-button--primary {
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  color: #fff;
  background-color: #21ba45;
  border: none !important;
  padding: 0.8em 2.8em;
}

.el-button.el-button--default.cancel-button:hover,
.el-button.el-button--default.cancel-button:focus {
  background: #2f3032 !important;
}

.form-footer .el-button.el-button--primary:hover,
.form-footer .el-button.el-button--primary:focus {
  background: #0ea432 !important;
}

/* .member-container .el-row.border-grid .el-col:not(:last-child) {
  border-right: 1px solid rgba(224, 230, 237, 0.5);
  border-right: 1px solid rgba(34, 36, 38, 0.15);
} */

.member-container .tac {
  text-align: center;
  padding-top: 1em !important;
  padding-left: 2.1em !important;
  padding-right: 0.2em !important;
}

.member-container .el-button--small {
  border: none !important;
  padding: 0.9em 1.4em 0.9em 1.5em !important;
  font-family: "Lato", sans-serif !important;
  border-radius: 4px 0 0 4px !important;
  /* background-color: #424f65 !important; */
  background-color: #e0e1e2 !important;
  border-radius: 0px 4px 4px 0 !important;
  color: rgba(0, 0, 0, 0.6);
}

.member-container .el-button--small:hover,
.member-container .el-button--small:focus {
  background-color: #313a4b !important;
  background: #c7c8c9 !important;
  color: rgba(0, 0, 0, 0.6);
}
.member-container .el-button--small:active,
.member-container .el-button--small:visited {
  background: #c7c8c9 !important;
}

/* .member-container .el-button--primary {
  background-color: #424f65 !important;
} */
.member-container .or-button {
  display: inline-block !important;
}

.member-container span.or-button {
  text-align: center !important;
  margin-left: -1.3em !important;
  position: absolute;
  left: 9.2em !important;
  color: rgba(0, 0, 0, 0.4);
  top: 1.4em !important;
  background: #fff;
  width: 1.9em;
  height: 1.8em;
  border-radius: 50%;
  line-height: 1.8em;
  z-index: 3 !important;
}

.member-container .el-button--small.create-file-btn {
  /* color: rgba(0, 0, 0, 0.6); */
  /* border-radius: 0px 4px 4px 0 !important; */
  background-color: #424f65 !important;

  border-radius: 4px 0 0 4px !important;
  border-radius: 4px !important;
  color: #fff !important;
}

.member-container .el-button--small.create-file-btn:hover,
.member-container .el-button--small.create-file-btn:focus {
  color: #fff !important;
  background-color: #74839c !important;
}
.member-container .el-button--small.create-file-btn:active {
  background-color: #74839c !important;
}

.member-container .el-button--small.create-file-btn:visited {
  background-color: #74839c !important;
}

.member-container .el-upload-list.el-upload-list--text {
  margin-left: -11.8em !important;
  text-align: left !important;
  width: 270% !important;
}

p.feedback-create-file {
  color: #74839c;
  margin-left: 0.2em;
  margin-top: 0.5em;
  font-weight: 400;
}

p.feedback-upload-file {
  color: rgba(0, 0, 0, 0.7);
  margin-left: 0.2em;
  margin-top: 0.5em;
  font-weight: 400;
}

.member-container .el-tag--gray {
  margin: 0.3em 0.1em 0.3em 0.3em !important;
}

.member-container .el-tag .el-icon-close {
  font-size: 10px !important;
}

button.PhotoPicker__photoPickerButton___2XdVn.Button__button___vS7Mv {
  font-size: 12px !important;
  text-transform: none !important;
  padding: 1em 1.2em 0.9em 1.2em !important;
  font-family: "Lato", sans-serif !important;
  border: none !important;
  border-radius: 0px 4px 4px 0px !important;
  background-color: #dbdcdd !important;
  letter-spacing: normal !important;
  margin-left: -3em !important;
  line-height: 12px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.form-inactive-button
  button.PhotoPicker__photoPickerButton___2XdVn.Button__button___vS7Mv {
  background-color: #dbdcdd !important;
}

.form-active-button
  button.PhotoPicker__photoPickerButton___2XdVn.Button__button___vS7Mv {
  background-color: #74839c !important;
  color: #fff !important;
}

.form-inactive-button .el-button--small.create-file-btn {
  background-color: #dbdcdd !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.form-active-button .el-button--small.create-file-btn {
  background-color: #74839c !important;
}

.el-col.el-col-5.tac.upload-file div input {
  width: 40% !important;
  left: 8em !important;
}

@media (min-width: 1250px) {
  .header-title {
    font-size: 3.8em !important;
  }
}

@media (min-width: 992px) and (max-width: 1249px) {
}

@media (min-width: 793px) and (max-width: 991px) {
  .header-title {
    font-size: 2.9em !important;
  }
  .studio-header.header-style {
    padding-bottom: 0 !important;
  }
  .header-align-button {
    margin-bottom: 0 !important;
  }
  .ui.animated.button {
    margin-bottom: 0.9em !important;
  }
  .el-button.el-button--info {
    position: absolute;
    top: 0rem;
    right: 0rem;
  }
}

/* @media (min-width: 767px) and (max-width: 793px) {
  .el-button.el-button--info {
    position: absolute;
    top: -4.6rem !important;
    right: -22.5rem !important;
  }
} */

/* @media (min-width: 764px) and (max-width: 991px) {
  .el-button.el-button--info {
    position: absolute;
    top: 0rem;
    right: 0rem;
  }
} */

@media (max-width: 793px) {
  .header-title {
    font-size: 2.6em !important;
  }
  .header-subtitle {
    font-size: 1.2em !important;
  }
  .studio-header.header-style {
    padding-bottom: 0 !important;
  }
  .header-align-button {
    margin-bottom: 1.75em !important;
  }
  .ui.animated.fade.button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .el-button.el-button--info {
    position: absolute;
    top: -4.6rem;
    right: -22.5rem;
  }
}
