.auth-form .password-errors {
	z-index: 1000;
	background: white;
	width: 100%;
	border: 1px solid rgba(0,0,0,0.87);
	border-radius: 4px;
	padding: 10px;
}

.auth-form .password-errors.float {
	position: absolute;
	left: 0;
	bottom: auto;
}