
.ui.card .content {
	padding: 1em 1em;
}
.ui.card .content .header {
	display: flex;
	justify-content: space-between;
}
.count-text {
	color: #424f65 !important;
	font-size: .8em;
	font-weight: 300;
}
