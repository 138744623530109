html,
body {
  margin: 0;
  background-color: #f4f8fb;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html, body {
  box-sizing: border-box;
}
*, *::after, *::before {
  box-sizing: inherit;
}

#root {
  max-width: 1280px;
  background-color: #f4f8fb;
  margin-left: auto;
  margin-right: auto;
  border: 0px;
  border-left: 1px solid #d0d7de;
  border-right: 1px solid #d0d7de;
  overflow: hidden;
}

#root .menu {
  max-width: 1280px;
  left: auto;
  right: auto;
}

.app-container {
  padding: 0em 5em;
  min-height: calc(100vh - 70px - 100px);
}

.app-login-cancel {
  text-align: center;
  font-size: xx-large;
}

@media (max-width: 550px) {
  .app-container {
    padding: 0em 3em;
  }
}

@media (max-width: 400px) {
  .app-container {
    padding: 1em 0.5em;
  }
}

.card-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card-container {
  max-width: 300px;
  margin: 0.3em;
}

.card-body {
  padding: 0.5em 1em;
}

.flex {
  display: flex;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 7px !important;
}

.mx-1 {
  margin: 0 0.5em;
}

.my-2 {
  margin: 1em 0;
}

.ml-2 {
  margin-left: 1em;
}

.mb-1 {
  margin-bottom: 7px;
}

.mb-mr {
  margin: 0 5px 5px 0;
}

.items-center {
  display: flex;
  align-items: center;
}

.pt-2 {
  padding-top: 1em;
}

.app-title {
  font-weight: 300;
  font-size: 1.5rem;
  color: #f90;
  margin: -3px;
}

.app-icon {
  height: 30px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.app-user {
  font-weight: 300;
  font-size: 1rem;
}

.text-right {
  text-align: right;
  margin: 0;
}

.text-green {
  color: green;
}

.message {
  z-index: 2000 !important;
}

.error {
  color: red;
}

.progress {
  margin: 1em;
}

.dialog {
  max-width: 500px;
}

/* center items in navbar if screen width < 520px */
@media (max-width: 520px) {
  .nav-container {
    justify-content: center;
  }
}

/* push items in navbar to opposite ends if screen width > 520px */
@media (min-width: 520px) {
  .nav-container {
    justify-content: space-between;
  }
}

/* remove the navbar item that contains the Greetings text if width > 672px */
@media (max-width: 690px) {
  .nav-items .el-menu-item:nth-child(1) {
    display: none !important;
  }
}

.nav-container {
  display: flex;
  flex-wrap: wrap;
}

.nav-link {
  text-decoration: none;
}

.profile-tabs > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-profile > * {
  background: rgba(255, 0, 0, 0.2);
}

.link {
  text-decoration: none;
  font-weight: bold;
  margin-right: 5px;
  color: var(--lightSquidInk);
}

.link:hover {
  text-decoration: underline;
}

.header {
  color: var(--color-purple);
  font-size: 1.2rem;
  padding: 5px 10px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.studio-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.studio-title {
  font-weight: 300;
  border-radius: 10px;
  /* text-decoration: #f90 solid underline;
  text-underline-position: under;
  text-decoration-style: wavy; */
  font-size: 2.5rem;
  display: flex;
  color: var(--lightSquidInk);
  align-items: center;
}

.studio-title-button {
  padding-left: 0.5em !important;
  color: #f90 !important;
  font-size: 30px !important;
}

@media (min-width: 640px) {
  .studio-title {
    font-size: 3rem;
  }
}

@media (max-width: 540px) {
  .studio-title {
    font-size: 2.1rem;
  }
  .studio-title-button {
    font-size: 25px !important;
  }
}

@media (max-width: 500px) {
  .studio-title {
    font-size: 1.8rem;
  }
  .studio-title-button {
    font-size: 25px !important;
  }
}

@media (max-width: 420px) {
  .studio-title {
    font-size: 1.6rem;
  }
  .studio-title-button {
    font-size: 20px !important;
  }
}

@media (max-width: 350px) {
  .studio-title {
    font-size: 1.5rem;
  }
  .studio-title-button {
    font-size: 15px !important;
  }
}

.image-preview {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.icon {
  margin-right: 5px;
}

.large-icon {
  height: 30px;
  margin-right: 2px;
}

.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-flow: row dense;
  justify-items: center;
  grid-gap: 0.2em;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 470px) {
  .hide-xs {
    display: none;
  }
}

*:focus {
  outline: none !important;
}

/* 2022 */

a {
  cursor: pointer;
}

.OrWrap {
  height: 50px;
  position: relative;
  margin: 5px;
}
.centre-line {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.centre-line:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  z-index: -1;
  background: #999;
}
.centre-line span {
  background-color: #fff;
  padding: 10px;
  display: inline-block;
}

/* VARS */

:root {
  --dxPrimary: #c29f58;
}
