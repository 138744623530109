.nav-text {
  font-family: "Lato", sans-serif;
  color: white !important;
  text-decoration: none !important;
  font-weight: 300 !important;
  transition: all linear 0.2s !important;
}
.nav-text:hover {
  cursor: pointer;
}
.nav-text:hover,
.nav-text:focus {
  color: #3caba5 !important;
}
.data-logo {
  width: 5.5em;
}

.logo-text {
  font-family: "Lato", sans-serif;
  color: white !important;
  font-size: 1.6em;
  font-weight: 500 !important;
  margin-left: 0.5em !important;
  text-decoration: none !important;
}

.navbar-style {
  background-color: #252d3a !important;
  /* padding: 0.5em 1.5em 0.2em 1em !important; */
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-style .item:hover,
.navbar-style .item:focus {
  background: none !important;
}

.nav-button {
  color: #fff !important;
  font-weight: 400 !important;
  padding: 0.1em 1.6em !important;
  margin: 0.5em 0.5em !important;
  background-color: #3caba5 !important;
  border-radius: 1px !important;
  transition: all linear 0.2s !important;
}

.nav-button:hover,
.nav-button:focus {
  background-color: #308984 !important;
}
