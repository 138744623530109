.create-first-project {
  width: 195px !important;
  height: 229.58px !important;
  margin: -12px 1em 1em -7px !important;
  background-color: #e2e2e2 !important;
  border: none !important;
  box-shadow: rgba(16, 14, 23, 0.1) 5px 5px 10px -2px !important;
  display: flex !important;
}

.create-first-project:hover,
.create-first-project:focus {
  background: #fff !important;
  box-shadow: rgba(16, 14, 23, 0.2) 8px 8px 15px -2px !important;
  border: none !important;
  transform: translateY(-3px) !important;
  z-index: 5 !important;
}

.ui.card.link.create-first-project p.add-first-project {
  font-size: 3.3em !important;
  font-weight: 600 !important;
  border: solid rgba(74, 78, 82, 0.3) 0.09em !important;
  border-radius: 50% !important;
  padding: 0px !important;
  width: 1.5em !important;
  height: 1.5em !important;
  line-height: 1.2em !important;
  text-decoration: none !important;
  text-align: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  color: rgba(74, 78, 82, 0.3) !important;
}
